import LoadScreen from '@piingltd/piing-ui/dist/shared/components/LoadScreen/LoadScreen';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { merge } from 'lodash';
import React, { useEffect, useState } from 'react';
import switchLanguage from '../../translations/switch-language';
import SettingsError from '../shared/SettingsError/SettingsError';

export const SettingsContext = React.createContext();
SettingsContext.displayName = 'SettingsContext';

export const SettingsContextProvider = ({ children }) => {
  const [queryStringOverrides, setQueryStringOverrides] = useState(null);
  const [error, setError] = useState(null);
  const [settings, setSettings] = useState(null);

  useEffect(() => { 
    if (queryStringOverrides != null) {
      const axiosClient = axios.create();
      axiosRetry(axiosClient);

      axiosClient
        .get(`${process.env.REACT_APP_BACKEND_HOSTNAME}/api/settings`, {
          'axios-retry': {
            retries: 100,
            retryDelay: (retryCount) => {
              return retryCount * 1000;
            }
          }
        })
        .then(({ data: settingsData }) => {
          const mergedSettings = merge(settingsData, queryStringOverrides);
          setSettings(mergedSettings);
        })
        .catch(err => {
          console.error(err);
          setError(err.message);
        });
    }
  }, [queryStringOverrides]);

  useEffect(() => {
    if (settings === null) return;
    switchLanguage(settings.language);
  }, [settings]);

  useEffect(() => {
    const ballBudgetQS = new URLSearchParams(window.location.search).get('ballBudget');
    setQueryStringOverrides(ballBudgetQS ? { playout: { ballBudget: parseInt(ballBudgetQS) } } : {});    
  }, []);

  return (
    <SettingsContext.Provider value={settings}>
      { settings && children}
      { !settings && !error && <LoadScreen />}
      { error && <SettingsError />}
    </SettingsContext.Provider>
  );
};