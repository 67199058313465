import React, { useRef, useEffect, useContext } from 'react';
import { Screen, Content } from '@piingltd/piing-ui/dist/admin/boilerplate/Screen';
import AdminBodyReset from '@piingltd/piing-ui/dist/admin/AdminBodyReset';
import styles from './settings-error.module.scss';
import { RiEmotionSadLine } from "react-icons/ri";

const SettingsError = () => {
  return (
    <Screen>
      <Content>
        <AdminBodyReset />
        <div className={styles.connecting}>
          <div className={styles.errorIcon}><RiEmotionSadLine /></div>
          <div>Something went wrong</div>
        </div>
      </Content>
    </Screen>
  )
}

export default SettingsError;

