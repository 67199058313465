export const translation = {
  "offline": {
    "title": "Tut uns leid!",
    "message": "Momentan findet kein Quiz statt"
  },
  "video": {
    "tapToUnmute": "Stummschaltung aufheben"
  },
  "countdown": {
    "soon": "In Kürze",
    "aboutToStart": "Gleich gehts los...",
    "kickOff": "Start"
  },
  "join": {
    "instruction": "Nimm am Quiz teil!",
    "joinInNow": "Jetzt mitmachen!",
    "screenName": "Nickname",
    "enterScreenName": "Gib hier deinen Namen ein!",
    "button": {
      "join": "Teilnehmen",
      "next": "Weiter"
    },
    "chooseTeam": "Wähle dein Team",
    "hello": "Hallo",
    "vs": "– vs –",
    "player": "Spieler",
    "players": "Spieler"
  },
  "form": {
    "name": "Name",
    "email": "Email",
    "mobile": "Telefonnummer",
    "iAgreeToThe": "Hiermit stimme ich den <0>{{terms}}</0> zu",
    "terms": "Teilnahmebedingungen",
    "termsLink": "https://piing.events/terms-quiiz",
    "ok": "OK"
  },
  "validation": {
    "nameTaken": "Dieser Name ist leider schon vergeben!",
    "problemJoining": "Es gab ein Problem beim Beitritt zum Quiz! ",
    "notReadyForPlayers": "Das Quiz ist noch nicht bereit für Spieler",
    "checkName": "Bitte trage deinen Namen ein",
    "checkEmail": "Bitte trage deine Emailadresse ein",
    "checkMobile": "Bitte trage deine Telefonnummer ein",
  },
  "leaderboard": {
    "teamsLeaderboard": "Team Rangliste",
    "playersLeaderboard": "Rangliste",
    "team": "Team",
    "name": "name",
    "score": "Punkte",
    "bonus": "Bonus",
    "wins": "hat gewonnen!",
    "tab": {
      "topPlayers": "Beste Spieler",
      "topTeams": "Beste Teams",
      "yourTeam": "Dein Team",
    }
  },
  "score": {
    "q": "Frage ",
    "correct": "Richtig",
    "incorrect": "Falsch",
    "speedBonus": "Speed bonus",
    "team": "Team",
    "overall": "Insgesamt",
    "score": "Punktestand",
    "rank": "Rang",
  },
  "question": {
    "question": "Frage",
    "milliseconds": "ms",
  }
};