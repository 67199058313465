import { setupI18N } from './i18n';
import { setupNumbro } from './numbro-helpers';

const SUPPORTED_LANGUAGES = ['en-GB', 'de-DE'];
const getLanguageCountry = language => language.substr(0, 2);

const switchLanguage = async (language) => {
  if (!SUPPORTED_LANGUAGES.includes(language)) {
    throw new Error(`Language ${language} is not supported.`);
  }
  setupNumbro(language);
  setupI18N(language);
  
  waitForConfirmic({maxAttempts: 3}).then(() => {
    window.Confirmic('configure', { language: getLanguageCountry(language) });
  }).catch(console.warn);
}

const waitForConfirmic = ({ maxAttempts = 10 }) => {
  return new Promise((resolve, reject) => {
    let attempts = 0;
    function checkConfirmic() {
      if(window.hasOwnProperty('Confirmic')) resolve();
      else attempts++ < maxAttempts ? setTimeout(() => checkConfirmic(), 1000) : reject('Confirmic could not initialise.');
    }
    checkConfirmic();
  });
}

export default switchLanguage;