import axios from 'axios';
import localStorage from 'mobx-localstorage';
import { store as authStore } from './store/auth-store';

export const axiosSecureBackend = axios.create({ baseURL: `${process.env.REACT_APP_BACKEND_HOSTNAME}` });
axiosSecureBackend.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('JWT')}`;
axiosSecureBackend.interceptors.response.use(function (response) {
  return response;
}, function (err) {
  if (err.response && err.response.status === 401) {
    authStore.logout();
  } else {
    return Promise.reject(err.response);
  }
});