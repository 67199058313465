import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translation as en } from './locale/en.js';
import { translation as de } from './locale/de.js';

const resources = {
  en: {
    translation: en
  },
  de: {
    translation: de
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en-GB',
    fallbackLng: 'en-GB', 
    interpolation: {
      escapeValue: false, // react escapes by default
    },
  });

export const setupI18N = (language) => {
  i18n.changeLanguage(language);
}

export default i18n;