export const translation = {
  "offline": {
    "title": "Sorry!",
    "message": "There's no quiz running at the moment"
  },
  "video": {
    "tapToUnmute": "Tap to unmute"
  },
  "countdown": {
    "soon": "Soon...",
    "aboutToStart": "Starting soon...",
    "kickOff": "Kick off"
  },
  "join": {
    "instruction": "Join the quiz!",
    "joinInNow": "Join in now!",
    "screenName": "Screen name",
    "enterScreenName": "Enter your screen name",
    "button": {
      "join": "Join",
      "next": "Next"
    },
    "chooseTeam": "Choose a team to join",
    "hello": "Hello",
    "vs": "– vs –",
    "player": "Player",
    "players": "Players"
  },
  "form": {
    "name": "Name",
    "email": "Email",
    "mobile": "Mobile",
    "iAgreeToThe": "I agree to the <0>{{terms}}</0>",
    "terms": "terms & conditions",
    "termsLink": "https://piing.events/terms-quiiz",
    "ok": "OK"
  },
  "validation": {
    "nameTaken": "That name has been taken!",
    "problemJoining": "There was a problem joining the game",
    "notReadyForPlayers": "We're not ready for players yet",
    "checkName": "Please check your name",
    "checkEmail": "Please check your email address",
    "checkMobile": "Please check your mobile number",
  },
  "leaderboard": {
    "teamsLeaderboard": "Teams Leaderboard",
    "playersLeaderboard": "Players Leaderboard",
    "team": "Team",
    "name": "Name",
    "score": "Score",
    "bonus": "Bonus",
    "wins": "wins!",
    "tab": {
      "topPlayers": "Top players",
      "topTeams": "Top teams",
      "yourTeam": "Your team",
    }
  },
  "score": {
    "q": "Q",
    "correct": "Correct",
    "incorrect": "Incorrect",
    "speedBonus": "Speed bonus",
    "team": "Team",
    "overall": "Overall",
    "score": "Score",
    "rank": "Rank",
  },
  "question": {
    "question": "Question",
    "milliseconds": "ms",
  }
};