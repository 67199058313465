import React from 'react';
import ReactDOM from 'react-dom';
import 'styles/index.scss';
import App from 'components/App';
import './translations/i18n';
import * as serviceWorker from 'serviceWorker';
import { CookiesProvider } from 'react-cookie';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
require('./store/auth-store'); // this store has a global logout listener, so we instantiate it here.

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://07636a4187cd4691b4516240b395d918@o986181.ingest.sentry.io/5942771",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
