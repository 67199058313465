import numbro from 'numbro';

const languageData = {
  'en-GB': require('numbro/languages/en-GB'),
  'de-DE': require('numbro/languages/de-DE'),
}

export const setupNumbro = (language) => {
  numbro.registerLanguage(languageData[language]);
  numbro.setLanguage(language);
}

export const ordinal = (number, forceShowOrdinal = false) => {
  const ignoreLanguages = ['de-DE'];
  const ignore = ignoreLanguages.includes(numbro.language());
  return ignore && !forceShowOrdinal ? '' : numbro(number).format({ output: "ordinal" });
}